<template>
  <div class="flex k1:gap-12 gap-6 k1:flex-row flex-col m-auto">
    <div class="flex flex-col w-full">
      <LTabs
        :labels="labels"
        @select="
          ({ label }) => {
            updateFilter('level', label);
          }
        "
      >
      </LTabs>
      <div class="flex k1:flex-row flex-col relative gap-4">
        <div class="flex k1:w-1/4 pt-3">
          <div class="flex flex-col w-full">
            <div
              class="flex border-b k:12 h-16 items-center mb-2 border-gray-400 w-full"
            >
              <!--              src="https://qtedu.eu/img/funnel-g.9e840350.svg" alt=""-->
              <!--              class="w-5" />-->

              <div
                class="flex gap-2 justify-between w-full group cursor-pointer k1:cursor-auto"
                @click="toggleFilter()"
              >
                <h3 class="ml-4 text-xl text-gray-500">Filters</h3>
                <button
                  class="flex rounded-full k1:hidden p-1 border-2 group-hover:bg-slate-100"
                >
                  <img
                    src="@/assets/images/down.png"
                    alt="EU Flag"
                    class="w-5 object-contain transition-all duration-300 self-center text-gradient-to-br from-pink-600 to-orange-500"
                    :class="{ 'rotate-180': isOpen }"
                  />
                </button>
              </div>
            </div>
            <div
              class="transition-all duration-200"
              :class="{ 'k1:visible hidden': !isOpen }"
            >
              <!--          class="transition-all duration-200 "-->
              <FilterButton
                class=""
                @click="updateFilter('category', 'Program')"
                :isActive="filter.category === 'Program'"
                >programs</FilterButton
              >
              <FilterButton
                @click="updateFilter('category', 'Course')"
                :isActive="filter.category === 'Course'"
                >courses</FilterButton
              >
              <FilterButton
                @click="updateFilter('category', 'Training')"
                :isActive="filter.category === 'Training'"
                >traınıng</FilterButton
              >
              <FilterButton
                @click="updateFilter('isOnline', true)"
                :isActive="filter.isOnline === true"
                >online activities</FilterButton
              >
              <FilterButton
                class="p-2"
                @click="updateFilter('isInternship', true)"
                :isActive="filter.isInternship === true"
                >internship opportunities</FilterButton
              >
              <FilterButton
                @click="updateFilter('isFree', true)"
                :isActive="filter.isFree === true"
                >free</FilterButton
              >

              <SelectButton
                class=""
                :data="filteredLanguages"
                @selectedOptions="onSelectedLanguage"
              >
              </SelectButton>
              <SelectButton
                :data="filteredTargets"
                @selectedOptions="onSelectedTarget"
              >
              </SelectButton>
              <!--
          <SelectButton>
            <template v-for="local in locations" :key="local">
              <option>{{ local }}</option>
            </template>
          </SelectButton>
          -->
              <div class="mx-auto my-4 flex items-center">
                <button
                  @click="filter = {}"
                  class="ResetBtn mx-auto px-4 py-2 rounded-3xl border border-indigo-900 dark:border-indigo-500 text-indigo-900 hover:text-white dark:text-indigo-500 hover:bg-indigo-900 group"
                >
                  <span class="flex items-center justify-center">
                    Reset
                    <IconReset
                      class="w-4 ml-4 group-hover:transform group-hover:rotate-180 transition duration-300"
                    />
                  </span>
                </button>
                <div class=""></div>
              </div>
            </div>
          </div>
        </div>
        <QtEduEntries
          :filter="filter"
          @languages="onLanguagesChange"
          @targets="onTargetsChange"
        >
        </QtEduEntries>
      </div>
    </div>
  </div>
</template>

<script>
import FilterButton from "@/components/qtEdu/FilterButton.vue";
import SelectButton from "@/components/qtEdu/SelectButton.vue";
import IconReset from "@/components/icons/IconReset.vue";
import LTabs from "@/components/layout/LTabs.vue";
import QtEduEntries from "@/components/qtEdu/QTEduEntries.vue";
import { ref, watch } from "vue";
import { isArray } from "../lib/getVariableType";

export default {
  components: {
    QtEduEntries,
    LTabs,
    FilterButton,
    SelectButton,
    IconReset,
  },
  setup() {
    const labels = [
      "All",
      "Primary and Secondary School",
      "Higher education",
      "Lifelong learning and (re)training",
      "Outreach",
    ];
    const locations = ["Filter by Location", ""];

    const isOpen = ref(window.innerWidth > 1200);
    const toggleFilter = () => {
      if (window.innerWidth > 1200) return;
      isOpen.value = !isOpen.value;
    };
    watch(
      () => window.innerWidth,
      (width) => {
        isOpen.value = width > 1200;
      }
    );
    const filter = ref({});

    const updateFilter = (key, value) => {
      console.log("KV", key, value);
      if (
        key in filter.value &&
        ((!isArray(value) &&
          (filter.value[key] === value || value === "All")) ||
          (isArray(value) && value.length == 0))
      ) {
        delete filter.value[key];
      } else {
        filter.value = {
          ...filter.value,
          [key]: value,
        };
      }
    };
    const filteredLanguages = ref(["Filter By Languages"]);
    const onLanguagesChange = (languages) => {
      return (filteredLanguages.value = [
        "Filter By Languages",
        ...languages.value,
      ]);
    };
    const filteredTargets = ref(["Filter By Targets"]);
    const onTargetsChange = (targets) => {
      return (filteredTargets.value = ["Filter By Targets", ...targets.value]);
    };

    const onSelectedLanguage = (language) => {
      updateFilter("lang", language.value);
    };
    const onSelectedTarget = (target) => {
      updateFilter("target", target.value);
    };

    return {
      filter,
      labels,
      filteredLanguages,
      filteredTargets,
      locations,
      updateFilter,
      toggleFilter,
      isOpen,
      onLanguagesChange,
      onTargetsChange,
      onSelectedLanguage,
      onSelectedTarget,
    };
  },
};
</script>
